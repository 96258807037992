<template>
  <div class="bg-white rounded-8">
    <slot name="top" />

    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th v-for="column of columns" :key="column.key">
              {{ column.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) of rows" :key="row[rowKey]">
            <TableData
              :column="column"
              :index="index"
              :key="column.key"
              :row="row"
              v-bind="column.props"
              v-for="column of columns"
            />
          </tr>
          <tr v-if="!rows.length">
            <td
              class="text-muted text-center"
              :class="{ 'is-loading is-loading-primary': isLoading }"
              :colspan="columns.length"
            >
              <template v-if="isLoading"> &nbsp; </template>
              <template v-else> Data tidak ditemukan </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <slot name="bottom" />
  </div>
</template>

<script>
import TableData from './TableData.vue';

export default {
  components: { TableData },

  props: ['columns', 'isLoading', 'rows', 'rowKey'],
};
</script>
