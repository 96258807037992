<template>
  <td v-if="column.component">
    <component
      :column="column"
      :is="column.component"
      :row="row"
      v-bind="column.componentProps"
    />
  </td>
  <td v-else v-html="html"></td>
</template>

<script>
import get from 'lodash/get';

export default {
  props: ['column', 'index', 'row'],

  computed: {
    html() {
      if (this.column.toHTML) return this.column.toHTML(this.row, this.index);
      return get(this.row, this.column.key);
    },
  },
};
</script>
