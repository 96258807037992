<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <Table
      rowKey="id"
      :isLoading="$store.getters['students/isFetching'](params)"
      :columns="columns"
      :rows="students"
    />
  </main>
</template>

<script>
import Schedule from '../../../../../store/models/Schedule';
import Student from '../../../../../store/models/Student';
import Task from '../../../../../store/models/Task';

import ScheduleInfo from '../../../../../components/student/schedule/ScheduleInfo.vue';
import Table from '../../../../../components/table/Table.vue';
import TableActions from '../../../../../components/teacher/task/answer/TableActions.vue';

export default {
  components: { ScheduleInfo, Table },

  computed: {
    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },

    task() {
      return Task.query().withAll().find(this.$route.params.task_id);
    },

    params() {
      return {
        $repositoryMethod: 'fetchByTaskId',
        $repositoryParams: [this.$route.params.task_id],
      };
    },

    /**
     * Manually get student models to get the correct answers.
     */
    students() {
      const result = this.$store.getters['students/getResult'](this.params);

      if (!result) return [];

      const students = Student.query()
        .withAllRecursive()
        // Get only the answer for the current task.
        .with('answers', (query) => {
          query.where('task_id', this.task.id);
        })
        .whereIdIn(result.items)
        .get();

      return students;
    },

    columns() {
      return [
        { key: 'user.name', text: 'Nama' },
        { key: 'nisn', text: 'NISN' },
        { key: 'nis', text: 'NIS' },
        {
          key: 'answered',
          text: 'Status',
          toHTML: (row) =>
            row.answers.length
              ? '<span class="text-success">Sudah Dijawab</span>'
              : '<span class="text-danger">Belum Dijawab</span>',
        },
        {
          key: 'answered_at',
          text: 'Tanggal Jawab',
          toHTML: (row) => {
            const answer = row.answers[0];
            return answer
              ? answer.createdAtJS.format('YYYY-MM-DD HH:mm')
              : null;
          },
        },
        {
          key: 'answers[0].score',
          text: 'Nilai',
          toHTML: (row) => {
            const score = row.get('answers[0].score');
            return score && `${score}<small class="text-muted">/100</small>`;
          },
        },
        {
          key: 'actions',
          component: TableActions,
          props: { class: 'text-end' },
        },
      ];
    },
  },

  metaInfo() {
    return { title: 'Jawaban' };
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus <b>${row.name}</b>?`,
        {
          onOK: async () => {
            try {
              // await answerRepository.destroy(row.id);
              this.$store.dispatch('answers/fetchAndInvalidate', this.params);
              this.$alert.success('Pelajaran berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      this.$store.dispatch('students/maybeFetch', this.params);
    },
  },

  async beforeMount() {
    const params = this.$route.params;

    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: params.schedule_id },
      });
    }

    if (!this.task) {
      await this.$store.dispatch('tasks/maybeFetch', {
        filter: { id: params.task_id },
      });
    }

    this.fetch();

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
      { path: this.schedule.tasksRoutePath, label: 'Tugas' },
      {
        path: `/guru/jadwal/${params.schedule_id}/tugas/${params.task_id}/ubah`,
        label: this.task.title,
      },
    ]);
  },
};
</script>
