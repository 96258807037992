<template>
  <router-link
    class="btn btn-sm btn-outline-secondary d-inline-flex align-items-center"
    :to="`/guru/jadwal/${$route.params.schedule_id}/tugas/${$route.params.task_id}/jawaban/${row.answers[0].id}`"
    v-if="row.answers[0]"
  >
    <EyeglassesIcon class="me-8" />
    Review
  </router-link>
</template>

<script>
import EyeglassesIcon from 'bootstrap-icons/icons/eyeglasses.svg';

export default {
  components: { EyeglassesIcon },

  props: ['row'],
};
</script>
